<template>
  <div>
    <div class="walletbox">
      <img class="bg" src="~img/account/bg@2x.png" alt="" />
      <div class="topNavBox">
        <div class="topbox clearfix">
          <a href="javaScript:" class="fl back" @click="seeBack">
            <img src="~img/book/back@2x.png" alt="" />
          </a>
          <p class="fl">錢包</p>
        </div>
        <div class="walletDetail">
          <p class="money">{{ userInfo.yuanBi }}</p>
          <p>書幣</p>
          <button @click="gotoup">充值</button>
        </div>
      </div>
    </div>
    <div class="goSeeBox">
      <ul class="centenList">
        <li class="clearfix">
          <router-link class="clearfix" to="/rechange">
            <img
              class="fl item"
              src="~img/account/icon_consume@2x.png"
              alt=""
            />
            <p class="fl">充值記錄</p>

            <img class="fr" src="~img/home/Path7@2x.png" alt="" />
          </router-link>
        </li>
        <li class="clearfix">
          <router-link class="clearfix" to="/consume">
            <img
              class="fl item"
              src="~img/account/icon_collection@2x.png"
              alt=""
            />
            <p class="fl">消費記錄</p>
            <img class="fr" src="~img/home/Path7@2x.png" alt="" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getuserInfo } from "apiurl/user";
export default {
  data() {
    return {
      userInfo: {}
    };
  },
  created() {
    this.$nextTick(() => {
      // 獲取用戶信息
      this.getUserInfo();
    });
  },
  methods: {
    seeBack() {
      this.$router.go(-1);
    },
    async getUserInfo() {
      let res = await getuserInfo();
      if (res.success) {
        this.userInfo = res.data;
      }
    },
    gotoup() {
      this.$router.push("/toup");
    }
  }
};
</script>

<style lang="stylus" scoped>
.walletbox
    position relative
    padding-bottom 42px
    .bg
        position absolute
        width 100vw
        top 0px
        z-index -1
    .topbox
        background  transparent
        p
            color #fff
    .walletDetail
        margin-top 39px
        text-align center
        font-size 26px
        color:rgba(255,255,255,1);
        .money
            font-weight:600;
            color:rgba(255,255,255,1);
            font-size:68px;
        button
            width:250px;
            height:71px;
            background:linear-gradient(180deg,rgba(255,255,255,1) 0%,rgba(255,255,255,0.78) 100%);
            border-radius:36px;
            color #4E89F8
            font-size:30px;
            font-weight:600;
            margin-top 27px
.goSeeBox
    background #fff
    border-radius:36px 36px 0 0;
    padding-top 15px
    min-height 500px
.centenList
    margin 10px 46px 0 52px
    li
        padding 27px 0
        border-bottom 2px solid #eee
        padding 27px 0 20px
        .item
            width 36px
            height 36px
        a
            img
                width 14px
                &.fr
                    margin-top 7px
        button
            width 87px
            height 45px
            padding 3px 16px
            background rgba(92, 162, 245, 1)
            border-radius 23px
            color #fff
        p
            color #323232
            line-height 36px
            font-size 30px
            margin-left 21px
            span
                color #999999
                display inline-block
                margin-left 5px
                font-size 24px
</style>
